import axios from "axios"

import {
  GET_POSTS_LOADING,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  GET_FIRST_POSTS_LOADING,
  GET_FIRST_POSTS_SUCCESS,
  GET_FIRST_POSTS_FAIL,
  GET_POST_DETAIL_LOADING,
  GET_POST_DETAIL_SUCCESS,
  GET_POST_DETAIL_FAIL,
} from "./types"

import { BASE_URL } from "../../utils/urls"

export const getPosts = () => (dispatch, getState) => {
  dispatch({ type: GET_POSTS_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/posts")
      .then(response => {
        resolve(dispatch({ type: GET_POSTS_SUCCESS, payload: response.data }))
      })
      .catch(error => {
        dispatch({ type: GET_POSTS_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}

export const getFirstPosts = () => (dispatch, getState) => {
  dispatch({ type: GET_FIRST_POSTS_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/posts?postCount=3")
      .then(response => {
        resolve(
          dispatch({ type: GET_FIRST_POSTS_SUCCESS, payload: response.data })
        )
      })
      .catch(error => {
        dispatch({ type: GET_FIRST_POSTS_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}

export const getPostDetails = postId => (dispatch, getState) => {
  dispatch({ type: GET_POST_DETAIL_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/posts/" + postId)
      .then(response => {
        resolve(
          dispatch({ type: GET_POST_DETAIL_SUCCESS, payload: response.data })
        )
      })
      .catch(error => {
        dispatch({ type: GET_POST_DETAIL_FAIL })
        if (error.response) {
          console.log(error.response.data)
        } else {
          console.log(error)
        }
        reject(error.response.data)
      })
  })
}
