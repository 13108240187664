import React, { useEffect } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import moment from "moment"

import Layout from "../components/layout"
import SEO from "../components/seo"
import bgImage from "../images/slider1_new.jpg"
import {
  getFirstProducts,
  addToCart,
  getProducts,
} from "../store/actions/productActions"
import { getFirstPosts } from "../store/actions/postActions"
import { getFirstFeedbacks } from "../store/actions/feedbackActions"
import { BASE_URL } from "../utils/urls"

const IndexPage = ({
  getFirstProducts,
  getFirstPosts,
  addToCart,
  getProducts,
  isProductLoading,
  firstProducts,
  isPostLoading,
  firstPosts,
  firstFeedbacks,
  getFirstFeedbacks,
}) => {
  useEffect(() => {
    getFirstProducts()
    getFirstPosts()
    getProducts()
    getFirstFeedbacks()
  }, [])

  return (
    <Layout>
      <SEO title="ACCUEIL" />
      <div className="flex justify-center">
        <div
          className="flex flex-col text-center pt-64 pb-48 w-full"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="text-2xl md:text-4xl font-bold text-white">
            Bienvenue sur Mon E-Pressing
          </div>
          <div className="flex-wrap pt-10">
            <Link
              to="/all-clothes"
              className="mx-4 text-white bg-blue-600 border-blue-600 hover:bg-blue-800 hover:border-blue-800 font-semibold py-3 px-4 rounded-full text-center shadow-lg text-xs"
            >
              Tarifs linges
            </Link>
            <Link
              to="/contact-us"
              className="mx-4 text-white bg-green-500 border-green-500 hover:bg-green-800 hover:border-red-800 font-semibold py-3 px-4 rounded-full text-center shadow-lg text-xs"
            >
              Contactez-nous
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center py-6">
        <div className="container mx-auto">
          <div className="flex flex-col max-w-full">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Nos services
            </span>
            <h1 className="text-center text-2xl  sm:text-xl lg:text-2xl">
              Personne particulière, services particuliers{" "}
              <span role="img" aria-labelledby="jsx-a11y/accessible-emoji">
                😉
              </span>
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              Nous passons récupérer vos vêtements où
              <br />
              vous le désirez et nous vous les envoyons plus propres,
              <br />
              plus éclatants 5 jours après le ramassage.
            </span>
          </div>
          <div className="flex flex-wrap pb-4 max-w-full">
            <div className="w-full sm:w-1/2 md:w-2/12 py-2">
              <div className="max-w-sm rounded overflow-hidden">
                <img
                  className="h-20 w-auto mx-auto"
                  src={require("../images/clothes-basket.png")}
                  alt=""
                />
                <div className="pb-2 text-center">
                  <div className="text-xs">Etape 1</div>
                  <div className="text-sm mb-2 mx-4">
                    <span className="text-blue-500">Ramassage</span> de vos
                    vêtements sales
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden md:block md:w-1/12 pt-16">
              <img
                className="h-8 w-auto mx-auto"
                src={require("../images/next.png")}
                alt=""
              />
            </div>

            <div className="w-full sm:w-1/2 md:w-2/12 py-2">
              <div className="max-w-sm rounded overflow-hidden">
                <img
                  className="h-20 w-auto mx-auto"
                  src={require("../images/truck.png")}
                  alt=""
                />
                <div className="pb-2 text-center">
                  <div className="text-xs">Etape 2</div>
                  <div className="text-sm mb-2 mx-4">
                    Nous <span className="text-blue-500">transportons</span> vos
                    vêtements
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden md:block md:w-1/12 pt-16">
              <img
                className="h-8 w-auto mx-auto"
                src={require("../images/next.png")}
                alt=""
              />
            </div>

            <div className="w-full sm:w-1/2 md:w-2/12 py-2">
              <div className="max-w-sm rounded overflow-hidden">
                <img
                  className="h-20 w-auto mx-auto"
                  src={require("../images/washing-machine.png")}
                  alt=""
                />
                <div className="pb-2 text-center">
                  <div className="text-xs">Etape 3</div>
                  <div className="text-sm mb-2 mx-4">
                    Nous faisons <span className="text-blue-500">laver</span>{" "}
                    vos vêtements
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden md:block md:w-1/12 pt-16">
              <img
                className="h-8 w-auto mx-auto"
                src={require("../images/next.png")}
                alt=""
              />
            </div>

            <div className="w-full sm:w-1/2 md:w-2/12 py-2">
              <div className="max-w-sm rounded overflow-hidden">
                <img
                  className="h-20 w-auto mx-auto"
                  src={require("../images/clean-clothes.png")}
                  alt=""
                />
                <div className="pb-2 text-center">
                  <div className="text-xs">Etape 4</div>
                  <div className="text-sm mb-2 mx-4">
                    Nous vous <span className="text-blue-500">livrons</span> vos
                    vêtements propres
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center py-6 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex flex-col">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Nos vêtements
            </span>
            <h1 className="text-center text-2xl">
              Des vêtements à faire laver ?
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              Vous êtes des personnes exceptionnelles, voilà pourquoi <br />
              Mon ePressing traite vos vêtements exceptionnellement.
            </span>
          </div>
          <div className="flex flex-wrap pb-4">
            {isProductLoading && (
              <div className="w-full mx-5">
                <div
                  className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
                  role="alert"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <div>Chargement en cours</div>
                </div>
              </div>
            )}
            {firstProducts.map(product => (
              <div
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-5 py-5"
                key={product.id}
              >
                <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
                  <img
                    className="w-full mx-auto"
                    style={{
                      height: "300px",
                      width: "auto",
                      alignSelf: "center",
                    }}
                    src={BASE_URL + "/storage/" + product.cover}
                    alt=""
                  />
                  <div className="py-2 text-center mx-4">
                    <div className="font-bold text-xl mb-2">{product.name}</div>
                    <div className="flex flex-col my-3">
                      <span className="text-base">
                        {product.selling_price + " FCFA"}
                      </span>

                      <div className="text-gray-600 text-xs pt-5">
                        <button
                          onClick={() => addToCart(product.id)}
                          className="px-2 py-2 rounded-full font-semibold bg-gray-100 border-gray-200 hover:bg-gray-200 hover:bg-gray-200"
                        >
                          Ajouter au panier
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center pb-6 pt-3">
            <Link
              to="/all-clothes"
              className="text-white bg-blue-600 border-blue-600 hover:bg-blue-800 hover:border-blue-800 font-semibold py-2 px-4 rounded text-center shadow-lg text-xs"
            >
              Plus de vêtements
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center py-6">
        <div className="container mx-auto">
          <div className="flex flex-col">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              A propos de nous
            </span>
            <h1 className="text-center text-2xl">
              Envie d'en savoir plus sur nous ?
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              Flemme de laver vos vêtements et ceux de votre famille ? <br />
              Ne vous inquiétez pas, nous le faisons pour vous !
            </span>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 px-5">
              <img
                src="https://media.istockphoto.com/photos/young-boy-doing-housework-at-home-picture-id922221748?b=1&k=6&m=922221748&s=170667a&w=0&h=6oDWQZMB4W0TR6l1ZfmHnwtaNoMJY9BD1p1B3xEhO9Q="
                alt=""
                className="w-full shadow-xl rounded-lg"
              />
            </div>
            <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 px-5">
              <p className="text-gray-600 text-sm text-left ">
                Nous sommes un service de pressing en ligne. <br />
                <span className="font-bold">Notre objectif :</span> <br />
                1- Vous faire gagner du temps
                <br />
                2- Vous offrir des vêtements propres bien LAVÉS à des prix très
                concurrentiels. <br />
                <span className="font-bold">Nos procédures :</span> <br />
                1- Vous nous communiquez la liste de vos vêtements à laver.{" "}
                <br />
                2- Nous vous revenons avec la facture. <br />
                3- Factures validées, nous passons ramasser vos vêtements à
                laver <br />
                4- Nous les traitons/lavons avec soins et nous vous les ramenons
                4 jours plus tard. Vous ne vous déplacez pas...
              </p>
              <Link
                to="/about-us"
                className="text-white bg-blue-600 border-blue-600 hover:bg-blue-800 hover:border-blue-800 font-semibold py-2 px-4 rounded text-center shadow-lg text-xs"
              >
                Lire plus
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center py-6 bg-gray-100">
        <div className="container mx-auto">
          <div className="flex flex-col">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Blog
            </span>
            <h1 className="text-center text-2xl">
              Des conseils pour garder vos vêtements neufs
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              “Il n'y a plus de mode, rien que des vêtements.“
              <br />
              <span className="font-bold">- Karl Lagerfeld (1933 - 2019)</span>
              <br />
              Laissez-nous les rendre propre 😁
            </span>
          </div>
          <div className="flex flex-wrap pb-4">
            {isPostLoading && (
              <div className="w-full mx-5">
                <div
                  className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
                  role="alert"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <div>Chargement en cours</div>
                </div>
              </div>
            )}
            {firstPosts.map(post => (
              <div
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 px-5"
                key={post.id}
              >
                <Link
                  to={"/posts/detail/" + post.id}
                  className="max-w-sm rounded overflow-hidden shadow-lg bg-white"
                >
                  <img
                    className="w-full h-48 rounded"
                    src={BASE_URL + "/storage/" + post.cover}
                    alt=""
                  />
                  <div className="pt-0 pb-2 mx-4 text-center">
                    <div className="font-bold text-base mb-2">{post.title}</div>
                    <p className="text-gray-600 italic text-xs">
                      Publié le&nbsp;
                      {moment(post.created_at).format("DD/MM/YYYY à HH:mm")}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="flex justify-center pt-3 pb-6">
            <Link
              to="/posts/list"
              className="text-white bg-green-500 border-green-500 hover:bg-green-800 hover:border-red-800 font-semibold py-2 px-4 rounded text-center shadow-lg text-xs"
            >
              Visitez notre blog
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col py-6 mb-0">
        <div className="container mx-auto">
          <div className="flex flex-col">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Avis client
            </span>
            <h1 className="text-center text-2xl">
              Quelques retours clients sur nos services
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              Nous souhaitons vraiment savoir ce que vous pensez de nos services
              <br />
              afin de les améliorer et de vous retourner vos vêtements toujours
              <br />
              aussi <strong>propres</strong> et <strong>éclatants</strong>.
            </span>
          </div>
          <div className="flex flex-wrap pb-4">
            {firstFeedbacks.map(feedback => (
              <div
                className="w-full sm:w-full md:w-1/3 px-5 py-2"
                key={feedback.id}
              >
                <div className="md:flex bg-white rounded-lg flex flex-col shadow">
                  <div className="flex w-full">
                    <iframe
                      width="100%"
                      height="325"
                      src={"https://www.youtube.com/embed/" + feedback.link}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="flex">
                    <img
                      className="w-10 h-10 rounded mx-4"
                      src={require("../images/icon.jpg")}
                      alt="Avatar of Jonathan Reinink"
                    />
                    <div className="text-xs">
                      <div className="text-gray-900">{feedback.name}</div>
                      <div className="text-gray-600">{feedback.position}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 px-5 py-2">
              <div className="md:flex bg-white rounded-lg p-4 flex flex-col shadow">
                <div className="flex w-full text-xs text-justify mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Nesciunt animi ab eum fugit labore. Dolor provident recusandae
                  in voluptas nulla? Maiores, nemo. Nemo odit temporibus fuga ab
                  est nam sequi.
                </div>
                <div className="flex">
                  <img
                    className="w-10 h-10 rounded-full mr-4"
                    src={require("../images/avatar.jpg")}
                    alt="Avatar of Jonathan Reinink"
                  />
                  <div className="text-xs">
                    <div className="text-gray-900">Jonathan Reinink</div>
                    <div className="text-gray-600">CTO mon e-pressing</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="flex justify-center py-6">
            <Link
              to="/contact-us"
              className="text-white bg-green-500 border-green-500 hover:bg-green-800 hover:border-red-800 font-semibold py-2 px-4 rounded text-center shadow-lg text-xs"
            >
              Souahitez-vous donner votre avis sur nos services ?
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    isProductLoading: state.product.isLoading,
    firstProducts: state.product.firstProducts,
    isPostLoading: state.post.isLoading,
    firstPosts: state.post.firstPosts,
    firstFeedbacks: state.feedback.firstFeedbacks,
  }
}

const mapDispatchToProps = {
  getFirstProducts,
  getFirstPosts,
  addToCart,
  getProducts,
  getFirstFeedbacks,
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
